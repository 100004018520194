/* eslint no-console:0 */
// Copia de app/javascript/js/base.js
import "../js/utils/events/globalEventsTracker";
import "../js/molecules/navigation/mainDropdown";
import "../js/molecules/navigation/events";
import "../js/molecules/fields/showPasswordField";
import "../js/organism/modalLogin/index";
import "../js/organism/modalCourses/index";
import "./amplitude/amplitude";
import "./alerts/fixedAlert";
import "../js/utils/widgets/apple";
import "../js/molecules/alertJuguetona/alertJuguetona";
import "../js/molecules/alertJuguetona/events";
import "../js/molecules/stickyBanner";
import "../js/organism/courseCard/events";
import "../js/organism/referralStatusPopover"; // to set up events to display referral status popovers (MGM)
import "../js/organism/tooltip";
import "../js/organism/followButton";
import "../js/molecules/fields/emailCorrectorField";
import "../js/molecules/fields/signUpForm";
import "../js/atoms/modals/modals";
import "../js/organism/topalert/events";
import "../js/organism/userMenu/events";
import "../js/molecules/footer/events";
import "../js/atoms/charsCounter";
import "../js/molecules/fields/enterFocusEmail";
import "../js/utils/pushNotification";
import "../js/molecules/search/expandSearch";
import "../js/atoms/forms/search-select";

import { Application } from "@hotwired/stimulus";
import Countdown from "../js/controllers/countdown_controller";
import Header from "../js/controllers/header_controller";

window.Stimulus = Application.start();
window.Stimulus.register("countdown", Countdown);
window.Stimulus.register("header", Header);
